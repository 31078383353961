<template>
  <div class="intellectualPropertyDescriptionAlls">
    <h1>知识产权声明</h1>
    <div class="intellectualPropertyDescription1">
      除明显归属于第三方所有的信息资料外，御之安科技拥有本网站所载的所有材料或内容，包括但不限于文字、图片、图形、表格、动画、程序、软件等单独或组合的版权。<br />
      任何被授权的浏览、复制、打印和传播属于本网站内的资料必须符合以下条件：<br />
      ——所有的资料和图象均以获得信息为目的；<br />
      ——所有的资料和图象均不得用于商业目的；<br />
      ——所有的资料、图象及其任何部分都必须包括此版权声明；<br />
      ——本网站上使用和显示及相关图形、标识等为御之安科技的注册商标。<br />
      未经深信服事先书面许可，任何人不得擅自使用御之安科技的任何知识产权，包括但不限于以非法的方式复制、传播、展示、镜像、上载、下载，或对本网站资料作全部和局部复制、转载、引用和链接等。否则，深信服将依法追究其法律责任。
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.intellectualPropertyDescriptionAlls {
  width: 100%;
  padding: 100px;
  h1 {
    text-align: center;
  }
  .intellectualPropertyDescription1 {
    margin: 0 auto;
    background: #ccc;
    font-size: 20px;
    padding: 50px;
  }
}
</style>